<template>
  <div
    class="flex flex-col items-center justify-center overflow-hidden"
    :style="[layoutStyle, borderStyle, bgStyle]"
    :class="[layoutClass, bgClass]"
  >
    <img :src="url" :style="imgStyle" :class="modeClass" />
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "ImageElement",
  mixins: [layoutMixin],
  props: {
    url: String,
  },
  data() {
    return {
      //hasImage: true,
    };
  },
  computed: {
    modeClass: function() {
      switch (this.layout.mode) {
        case 0:
          return "object-contain";

        case 1:
          return "object-fill";

        case 2:
          return "object-cover";

        case 3:
          return "object-none";

        default:
          return "object-none";
      }
    },
    imgStyle: function() {
      var width = this.w;
      var height = this.h;
      if (this.u === "%") {
        width = (window.innerWidth * width) / 100;
        height = (window.innerHeight * height) / 100;
      }

      return {
        width: width + "px",
        height: height + "px",
      };
    },
  },
};
</script>

<style scoped></style>
